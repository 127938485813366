<template>
    <div>
        <p>Unbekannter Pfad: {{ $route.path }}</p>
        <button @click="$router.push('/')">zur Startseite</button>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>
